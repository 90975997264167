import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { SelectCustomer, SetFilterFormExpanded } from './project-filter.actions';

export const projectFilterFeatureKey = 'project-filter';

export interface State {
  filterFormExpanded: boolean;
  selectedCustomerId: number | null;
}

export interface AppState {
  'project-filter': State;
}

export const initialProjectFilterState: State = {
  filterFormExpanded: false,
  selectedCustomerId: null,
};

const projectFilterReducer = createReducer(
  initialProjectFilterState,
  on(SelectCustomer, (state, { customerId }): State => ({ ...state, selectedCustomerId: customerId })),
  on(SetFilterFormExpanded, (state, { expanded }): State => ({ ...state, filterFormExpanded: expanded })),
);

export function reducer(state: State | undefined, action: Action) {
  return projectFilterReducer(state, action);
}

export const selectProjectFilterState = createFeatureSelector< State>(projectFilterFeatureKey);
export const selectSelectedCustomerId = createSelector(
  selectProjectFilterState,
  (state: State) => state.selectedCustomerId,
);
export const selectFilterFormExpanded = createSelector(
  selectProjectFilterState,
  (state: State) => state.filterFormExpanded,
);
