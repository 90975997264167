import { createAction, props, union } from '@ngrx/store';

export const SelectCustomer = createAction('[ProjectFilter] SELECT_CUSTOMER', props<{ customerId: number }>());
export const SetFilterFormExpanded = createAction(
  '[ProjectFilter] SET_FILTER_FORM_EXPANDED',
  props<{ expanded: boolean }>(),
);

export const ProjectFilterActions = union({
  SelectCustomer,
  SetFilterFormExpanded,
});
